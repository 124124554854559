import { useState } from "react";
import Loading from "../Loading";
import Mirror from "../Mirror";
import classNames from "classnames";

function Mobile({ display = true, isolate, url, title = "" }) {
  const [loading, setLoading] = useState(true);

  return (
    <div
      className={classNames("duration-1000", {
        "absolute -bottom-24 xl:-bottom-10 left-60 w-[150px] pb-12": !isolate,
        "absolute left-0 right-0 top-0 w-[280px] 3xs:w-[300px] mx-auto pb-12":
          isolate,
        "translate-y-full opacity-0": !display,
      })}
    >
      <Mirror path="img/device/mobile.png" alt="Mobile" />
      <div
        className={classNames(
          "absolute left-0 right-0 bottom-[90px] m-auto flex justify-center w-[88%] overflow-hidden bg-white",
          {
            "top-[10%] h-[70%]": !isolate,
            "top-[4.1%] md:top-[4%] h-[75%]": isolate,
          }
        )}
      >
        <iframe
          src={url}
          width="414"
          height="766"
          title={title.length < 1 ? "Preview: " + url : title}
          className={classNames(
            "absolute top-0 left-0 right-0 m-auto origin-top-left",
            {
              "scale-[0.332]": !isolate,
              "scale-[0.64] 3xs:scale-[0.638] md:scale-[0.665]": isolate,
            }
          )}
          onLoad={() => setLoading(false)}
        ></iframe>
        {loading && <Loading />}
      </div>
    </div>
  );
}

export default Mobile;
