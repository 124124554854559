import { useState } from "react";
import Loading from "../Loading";
import Mirror from "../Mirror";
import classNames from "classnames";

function Tablet({ display = true, isolate, url, title = "", rotate = false }) {
  const [loading, setLoading] = useState(true);

  return (
    <div
      className={classNames("duration-1000 origin-center ", {
        "absolute w-[280px] left-3 xl:left-0 -bottom-20 xl:bottom-0 pb-12 z-0":
          !isolate,
        "absolute w-[190px] 3xs:w-[200px] 2xs:w-[240px] xs:w-[320px] md:w-[360px] lg:w-[440px]":
          isolate,
        "left-0 right-0 m-auto top-0": isolate && !rotate,
        "-translate-x-full opacity-0": !display,
        "rotate-90 left-0 right-0 mx-auto -top-[5%]": rotate,
      })}
    >
      <Mirror
        path="img/device/tablet.png"
        alt="Tablet"
        small={true}
        rotate={rotate}
      />
      <div
        className={classNames(
          "absolute right-0 bottom-[82px] m-auto flex justify-center overflow-hidden bg-white rounded-lg",
          {
            "top-[7.5%] h-[83.42%] w-[92%] -left-px": !isolate,
            "top-[30%] 3xs:top-[29%] 2xs:top-[24%] xs:top-[18%] md:top-[16%] lg:top-[13%] h-[94%] w-[93%] left-0 lg:-left-1":
              isolate,
          }
        )}
      >
        <iframe
          src={url}
          width={rotate ? "1079" : "768"}
          height={rotate ? "750" : "1120"}
          title={title.length < 1 ? "Preview: " + url : title}
          className={classNames(
            "absolute top-0 left-0 right-0 m-auto origin-top-left",
            {
              "scale-[0.344]": !isolate,
              "scale-[0.23] 3xs:scale-[0.242] 2xs:scale-[0.291] xs:scale-[0.389] md:scale-[0.435] lg:scale-[0.546]":
                isolate,
              "-rotate-90 top-full -left-[82%] w-[69rem] lg:w-[67.4375rem] h-[48.125rem] lg:h-[46.875rem]":
                rotate,
            }
          )}
          onLoad={() => setLoading(false)}
        ></iframe>
        {loading && <Loading />}
      </div>
    </div>
  );
}

export default Tablet;
