// Utility function to generate a random number between `from` and `to` (inclusive)
const randomInRange = (from, to) =>
  Math.floor(Math.random() * (to - from + 1) + from);

// Utility function to pick a random argument from the provided arguments
const pickRandom = (...args) => args[randomInRange(0, args.length - 1)];

// Function to get a random character from specific Unicode ranges
const getRandomChar = () =>
  String.fromCharCode(
    pickRandom(
      randomInRange(0x0020, 0x003f),
      randomInRange(0x1980, 0x19df),
      randomInRange(0x2100, 0x2110),
      randomInRange(0x2360, 0x237f),
      randomInRange(0x3041, 0x30ff),
      randomInRange(0x06b2, 0x06d3),
      randomInRange(0xa840, 0xa877)
    )
  );

// Function to loop a given function `fn` with a delay
const loopWithDelay = (fn, delay) => {
  let lastExecutionTime = Date.now();

  const loop = () => {
    if (Date.now() - lastExecutionTime >= delay) {
      fn();
      lastExecutionTime = Date.now();
    }
    requestAnimationFrame(loop);
  };

  requestAnimationFrame(loop);
};

class Char {
  constructor() {
    this.element = document.createElement("span");
    this.mutate();
  }

  mutate() {
    this.element.textContent = getRandomChar();
  }
}

class Trail {
  constructor(list = [], { size = 10, offset = 0 } = {}) {
    this.list = list;
    this.options = { size, offset };
    this.body = [];
    this.move();
  }

  traverse(fn) {
    this.body.forEach((item, index) => {
      if (item) fn(item, index, index === this.body.length - 1);
    });
  }

  move() {
    const { offset, size } = this.options;
    this.body = [];

    for (let i = 0; i < size; ++i) {
      const item = this.list[offset + i - size + 1];
      this.body.push(item);
    }

    this.options.offset = (offset + 1) % (this.list.length + size - 1);
  }
}

const hue = 138;

class Rain {
  constructor({ target, row = 20 }) {
    this.element = document.createElement("p");
    this.build(row);
    if (target) target.appendChild(this.element);
    this.startDropping();
  }

  build(row) {
    const fragment = document.createDocumentFragment();
    const chars = [];

    for (let i = 0; i < row; ++i) {
      const char = new Char();
      fragment.appendChild(char.element);
      chars.push(char);

      if (Math.random() < 0.5) {
        loopWithDelay(() => char.mutate(), randomInRange(1000, 5000));
      }
    }

    this.trail = new Trail(chars, {
      size: randomInRange(10, 30),
      offset: randomInRange(0, 100),
    });
    this.element.appendChild(fragment);
  }

  startDropping() {
    const delay = randomInRange(80, 80);
    loopWithDelay(() => {
      this.trail.move();
      this.trail.traverse((char, index, isLast) => {
        char.element.style = `
          color: hsl(${hue}, 100%, ${
          (85 / this.trail.body.length) * (index + 1)
        }%);
        `;

        if (isLast) {
          char.mutate();
          char.element.style = `
            color: hsl(${hue}, 100%, 85%);
            text-shadow: 0 0 .5em #fff, 0 0 .5em currentColor;
          `;
        }
      });
    }, delay);
  }
}

/**
 * Initializes the rain effect by creating Rain instances.
 *
 * @param {number} [column=40] - The number of columns, each character/column width is 5 pixels.
 * @param {number} [row=25] - The number of rows, each character/row height is 6 pixels.
 * @param {Element} target - The target DOM element where the rain effect will be applied.
 */
export default function initializeRain(column = 40, row = 25, target) {
  if (target) {
    for (let i = 0; i < column; ++i) {
      new Rain({ target, row });
    }
  }
}
