const Footer = () => {
  return (
    <footer>
      <div className="md:container flex mx-auto px-5 md:px-10 3xl:px-5 py-7 md:py-4 border-t mt-10 justify-between">
        <span>Thank you for visiting 🙂</span>
        <span>Version: 1.0.2</span>
      </div>
    </footer>
  );
};

export default Footer;
