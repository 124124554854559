function Loading() {
  return (
    <div className="w-full h-full bg-white z-10">
      <img
        className="absolute left-0 right-0 top-0 bottom-0 m-auto"
        src="img/loading.gif"
        alt="Loading"
      />
    </div>
  );
}

export default Loading;
