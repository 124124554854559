import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";
import useFetchData from "./hooks/useFetchData";
import "swiper/css/bundle";

const Testimonials = () => {
  const {
    data: testimonies,
    loading,
    error,
  } = useFetchData("/data/testimonies.json");

  if (loading) {
    return <div className="fetch-loading">Loading...</div>;
  }

  if (error) {
    return <div className="fetch-error">Error: {error}</div>;
  }

  return (
    <div className="md:container xl:w-[1200px] max-w-full mx-auto px-5 md:px-10 3xl:px-5 pb-10 text-gray-600 leading-7 tracking-wide">
      <div className="">
        <h2 className="text-xl font-bold mb-8 text-center">Full-Time Role</h2>
        <TestimonialCard testimonies={testimonies.fulltime} />
      </div>
      <div className="mt-16 mb-10">
        <h2 className="text-xl font-bold mb-10 text-center">Freelance</h2>
        <TestimonialCard testimonies={testimonies.freelance} reversed={1} />
      </div>
    </div>
  );
};

export default Testimonials;

function TestimonialCard({ testimonies, reversed = 0 }) {
  return (
    <Swiper
      effect={"cards"}
      grabCursor={true}
      modules={[EffectCards]}
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      autoplay={true}
    >
      {testimonies.map((client, index) => (
        <SwiperSlide
          key={client.id}
          className="bg-white p-6 xs:p-10 rounded-2xl shadow-[0px_4px_10px_rgb(0_0_0/10%)] border border-[#efefef] border-solid"
        >
          <div
            className={`flex items-center flex-col md:flex-row gap-5 xs:gap-8 ${
              index % 2 === reversed ? "md:!flex-row-reverse" : ""
            }`}
          >
            <div className="md:w-1/4 text-center">
              <img
                className="w-32 h-32 m-auto mb-4 rounded-full"
                src={client.photo}
                alt={client.name}
              />
              <h3 className="font-bold text-xl">{client.name}</h3>
              <h4>{client.title}</h4>
            </div>
            <div className="md:w-3/4 text-balance">
              <p>{client.testimony}</p>
              <span className="text-orange-400 text-xl">
                {Array.from({ length: client.rating }, (_, i) => (
                  <span key={i}>★</span>
                ))}
              </span>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
