import Available from "./Available";

const Header = ({ up }) => {
  return (
    <header>
      <div className="md:container m-auto px-5 md:px-10 3xl:px-4 pt-5 pb-7 md:pt-10 md:pb-14">
        <div>
          <h2 className="text-[1.5rem] 3xs:text-[1.6rem] sm:text-[2rem] bg-clip-text text-transparent bg-gradient-to-r from-[#607d8b] to-[#607d8b] tracking-wide uppercase font-bold">
            Muhammad Russell
          </h2>
          <h3 className="font-semibold text-xl text-gray-400 mb-4 mt-1 xs:mt-0">
            Full-Stack Developer
          </h3>
          {/* <span>Resume</span> */}
          <Available fontSize="xl" up={up} />
        </div>
      </div>
    </header>
  );
};

export default Header;
