import { useState } from "react";
import useFetchData from "./hooks/useFetchData";

const Certificates = () => {
  const [expandedCertificates, setExpandedCertificates] = useState({});

  const toggleDescription = (id) => {
    setExpandedCertificates((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const {
    data: certificateData,
    loading,
    error,
  } = useFetchData("/data/certificates.json");

  if (loading) {
    return <div className="fetch-loading">Loading...</div>;
  }

  if (error) {
    return <div className="fetch-error">Error: {error}</div>;
  }

  return (
    <div className="md:container mx-auto px-5 md:px-10 3xl:px-5 pt-5 md:pt-8 pb-14">
      <div className="flex flex-col xl:flex-row flex-wrap gap-10">
        {certificateData.certificates.map((certificate) => (
          <div
            key={certificate.id}
            className="xl:w-[calc(50%-1.25rem)] flex flex-col items-center md:flex-row border p-5 2xs:p-8 gap-10 rounded-2xl shadow-md"
          >
            <img
              className="w-40 h-auto"
              src={certificate.badge}
              alt={certificate.title}
            />
            <div className="flex-1 text-gray-600">
              <h3 className="text-xl text-black font-semibold mb-2">
                {certificate.title}
              </h3>
              <p className="mb-1">Provider: {certificate.provider}</p>
              <p className="mb-2">Issued: {certificate.issued}</p>
              {certificate.description && (
                <p
                  className={`mb-2 ${
                    expandedCertificates[certificate.id]
                      ? ""
                      : "text-ellipsis overflow-hidden"
                  }`}
                >
                  Description:{" "}
                  {expandedCertificates[certificate.id]
                    ? certificate.description
                    : certificate.description.slice(0, 100)}
                  {certificate.description.length > 100 &&
                    !expandedCertificates[certificate.id] &&
                    "..."}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => toggleDescription(certificate.id)}
                  >
                    {expandedCertificates[certificate.id]
                      ? " show less"
                      : " show more"}
                  </span>
                </p>
              )}
              {certificate.verification && (
                <p className="mb-1">
                  Verification:{" "}
                  <a
                    href={certificate.verification}
                    className="break-all"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {certificate.verification}
                  </a>
                </p>
              )}
              <p className="mb-2">Tags: {certificate.skills.join(", ")}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Certificates;
