import React, { useEffect, useRef } from "react";
import "./rain.css";
import initializeRain from "./RainScript";

function matrix() {
  let targets = document.querySelectorAll(".rain");
  targets.forEach((target) => {
    let targetWidth = target.clientWidth;
    let targetHeight = target.clientHeight;
    initializeRain(
      Math.ceil(targetWidth / 5),
      Math.ceil(targetHeight / 6),
      target
    );
  });
}

const Rain = ({ content, containerClasses, contentClasses }) => {
  const initialized = useRef(false);

  useEffect(() => {
    const initMatrix = () => {
      if (!initialized.current) {
        matrix();
        initialized.current = true;
      }
    };
    initMatrix();
  }, []);

  return (
    <div
      className={`inline-block relative overflow-hidden leading-none ${containerClasses}`}
    >
      <div className="rain"></div>
      <div
        className={`select-none rain-text ${
          contentClasses ?? "mix-blend-lighten bg-white"
        }`}
      >
        {content}
      </div>
    </div>
  );
};

export default Rain;
