const Career = () => {
  return (
    <div className="md:container xl:w-[1200px] max-w-full mx-auto px-5 md:px-10 3xl:px-5">
      <div className="text-gray-600 leading-7 tracking-wide pb-16">
        <h3 className="font-bold mt-4 mb-10 uppercase text-center text-xl">
          Career Highlights:
        </h3>

        <div className="p-6 xs:p-8 border rounded-xl shadow-md mb-10">
          <div className="flex justify-between items-start mb-3">
            <h2 className="font-bold text-xl flex gap-2">
              <span className="text-black">Freelance</span> - Upwork
            </h2>
            <span className="bg-green-500 text-white font-bold text-sm px-3 py-1 rounded-md">
              2024 - Present
            </span>
          </div>
          <p>
            Currently freelancing on Upwork, I'm focused on expanding my skill
            set and exploring new technologies. This flexible path enables
            continuous learning while allowing me to deliver impactful solutions
            for clients.
          </p>
        </div>

        <div className="p-6 xs:p-8 border rounded-xl shadow-md mb-10">
          <div className="flex justify-between items-start mb-3">
            <h2 className="font-bold text-xl flex gap-2">
              <span className="text-black">Full-time</span> - Consumer Attorney
              Marketing Group
            </h2>
            <span className="bg-green-500 text-white font-bold text-sm px-3 py-1 rounded-md">
              2018 - 2024
            </span>
          </div>
          <p>
            I spent 6.5 years as a remote developer at Consumer Attorney
            Marketing Group in Los Angeles, building high-performance landing
            pages, custom WordPress websites, and Unbounce campaigns. I worked
            closely with digital team on A/B testing and collaborated with
            programmers team crafting backend solutions using PHP/Laravel. This
            role deepened my expertise in managing large MySQL databases and
            sharpened my skills with tools like Docker, Git, and DBeaver.
          </p>
        </div>

        <div className="p-6 xs:p-8 border rounded-xl shadow-md">
          <div className="flex justify-between items-start mb-3">
            <h2 className="font-bold text-xl flex gap-2">
              <span className="text-black">Freelance</span> - Fiverr
            </h2>
            <span className="bg-green-500 text-white font-bold text-sm px-3 py-1 rounded-md">
              2015 - 2018
            </span>
          </div>
          <p>
            Starting as a freelance web developer on Fiverr, I achieved Level
            Two Seller status through excellent client satisfaction. Working
            with a diverse range of clients globally, I quickly adapted to
            various environments, enhancing my ability to meet unique project
            requirements and exceed client expectations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Career;
