import { useEffect } from "react";

const ScrollTo = ({ target }) => {
  useEffect(() => {
    const targetElement = document.querySelector(target);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [target]);

  return null;
};

export default ScrollTo;
