import { useState } from "react";
import Loading from "../Loading";
import classNames from "classnames";

function Desktop({ isolate, display = true, url, title = "" }) {
  const [loading, setLoading] = useState(true);

  return (
    <div
      className={classNames(
        "relative w-[280px] 3xs:w-[300px] 2xs:w-[340px] xs:w-[400px] md:w-[700px] xl:w-auto inline-block text-center z-0 max-md:mb-4 duration-1000",
        {
          "-translate-y-full opacity-0": !display,
        }
      )}
    >
      <img
        className="m-auto pointer-events-none select-none"
        src="img/device/desktop.png"
        alt="Desktop"
      />
      <div className="absolute top-[4.3%] left-0 right-0 m-auto flex justify-center w-[91.648%] h-[60.1%] bg-white">
        <iframe
          src={url}
          width="1624"
          height="972"
          title={title.length < 1 ? "Preview: " + url : title}
          className="absolute top-0 left-0 right-0 m-auto scale-[0.158] 3xs:scale-[0.17] 2xs:scale-[0.1935] xs:scale-[0.226] md:scale-[0.396] xl:scale-50 origin-top-left"
          onLoad={() => setLoading(false)}
        ></iframe>
        {loading && <Loading />}
      </div>
    </div>
  );
}

export default Desktop;
