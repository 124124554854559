const Available = ({ fontSize = "2xl", hello = false }) => {
  return (
    <div className="flex gap-4 flex-wrap 2xs:justify-between flex-col 2xs:flex-row">
      <div className="flex gap-5">
        <p
          className={`flex items-center text-xl xs:text-${fontSize} gap-2 text-[#666]`}
        >
          <span className="relative flex h-2 w-2 mt-1">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-600 opacity-50 duration-[2000]"></span>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
          </span>
          Available Now
        </p>
        {/*
        <div className="flex gap-4">
          <a
            className="cursor-pointer"
            href="https://www.upwork.com/freelancers/muhammadrussell"
            target="_blank"
            rel="noreferrer"
          >
            <img className="w-9" src="img/icon/upwork.png" alt="UpWork" />
          </a>
          {!up && (
            <a
              className="cursor-pointer"
              href="https://www.linkedin.com/in/muhammad-russell/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-9" src="img/icon/linkedin.png" alt="LinkedIn" />
            </a>
          )}
        </div>
        */}
      </div>
      {/* {!hello && !up && (
        <a
          className="md:hidden px-3 py-1 text-sm font-bold text-white bg-[#4682b4] uppercase leading-7 rounded-md text-center"
          href="Resume_Muhammad_Russell.pdf"
        >
          Resume
        </a>
      )} */}
    </div>
  );
};

export default Available;
