import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

const baseElement = document.getElementById("baseURL");
if (baseElement && process.env.REACT_APP_BASE_URL) {
  baseElement.setAttribute("href", process.env.REACT_APP_BASE_URL);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <div className="scroll-wrapper overflow-x-hidden">
      <App />
    </div>
  </React.StrictMode>
);
