import classNames from "classnames";

function Mirror({ path, alt, small, rotate = false }) {
  return (
    <>
      <img
        className="m-auto pointer-events-none select-none"
        src={path}
        alt={alt}
      />
      <img
        className={classNames(
          `m-auto absolute left-0 right-0 opacity-20 [mask-image:linear-gradient(to_bottom,rgb(0,0,0),rgba(0,0,0,0)_15px)] lg:[mask-image:linear-gradient(to_bottom,rgb(0,0,0),rgba(0,0,0,0)_${
            small ? 15 : 30
          }px)]`,
          {
            "top-0 left-full [mask-image:linear-gradient(to_right,rgb(0,0,0),rgba(0,0,0,0)_15px)] lg:[mask-image:linear-gradient(to_right,rgb(0,0,0),rgba(0,0,0,0)_15px)]":
              rotate,
          }
        )}
        src={path}
        alt={alt}
      />
    </>
  );
}

export default Mirror;
