import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ScrollTo from "./components/ScrollTo";
import Desktop from "./components/Preview/Desktop";
import Laptop from "./components/Preview/Laptop";
import Tablet from "./components/Preview/Tablet";
import Mobile from "./components/Preview/Mobile";
import classNames from "classnames";
import useWindowSize from "./hooks/useWindowSize";
import useFetchData from "./hooks/useFetchData";

const Preview = ({ external = false }) => {
  const { type, id } = useParams();
  const projectId = parseInt(id, 10);

  const [searchParams] = useSearchParams();
  const previewURL = searchParams.get("url");

  const [deviceSelected, setDeviceSelected] = useState(false);
  const [device, setDevice] = useState("All");
  const isolate = device !== "All";
  const devices = [
    { name: "Desktop", src: "img/icon/desktop.png", alt: "Desktop" },
    { name: "Laptop", src: "img/icon/laptop.png", alt: "Laptop" },
    { name: "Tablet", src: "img/icon/tablet.png", alt: "Tablet" },
    { name: "Mobile", src: "img/icon/mobile.png", alt: "Mobile" },
  ];
  const { windowWidth } = useWindowSize();

  useEffect(() => {
    if (windowWidth < 1024 && device === "All") {
      setDevice("Desktop");
    } else if (windowWidth >= 1024 && !deviceSelected) {
      setDevice("All");
    }
    // eslint-disable-next-line
  }, [windowWidth]);

  const selectDevice = (device) => {
    setDevice(device);
    setDeviceSelected(true);
  };

  const [rotate, setRotate] = useState(false);
  const toggleRotate = () => {
    setRotate((rotate) => {
      return rotate ? false : true;
    });
  };

  const {
    data: projectData,
    loading,
    error,
  } = useFetchData("/data/projects.json");

  if (loading) {
    return <div className="fetch-loading">Loading...</div>;
  }

  if (error) {
    return <div className="fetch-error">Error: {error}</div>;
  }

  const projectList = projectData[type];
  const project = external ? 0 : projectList.find((p) => p.id === projectId);

  const tempURL = !external && project.id > 0 ? project.src : previewURL;
  const url = tempURL ? tempURL : window.location.origin + "/about";

  return (
    <>
      <ScrollTo target="nav" />
      <div
        className={classNames("md:container mx-auto px-4 overflow-hidden", {
          "pb-28 xl:pb-12": device === "All",
        })}
      >
        <PageURL srcUrl={url} />
        <div className="relative flex flex-col xs:flex-row items-center justify-center gap-2 3xs:gap-5 xs:gap-10 font-bold mb-10 select-none z-20">
          <div className="flex items-center gap-4">
            Devices:
            <span className="inline-flex items-center h-8 2xs:h-10 [&>img]:max-h-full [&>img]:cursor-pointer [&>img.active]:border-b-2 [&>img.active]:border-red-600 gap-3 2xs:gap-4">
              {devices.map((deviceData) => (
                <img
                  key={deviceData.name}
                  className={classNames({
                    active: device === deviceData.name,
                  })}
                  onClick={() => selectDevice(deviceData.name)}
                  src={deviceData.src}
                  alt={deviceData.alt}
                  aria-label={deviceData.name}
                />
              ))}
            </span>
          </div>
          {device === "Tablet" && (
            <div className="flex items-center gap-2 select-none">
              Rotate:
              <img
                className="h-8 2xs:h-10 cursor-pointer"
                src="img/icon/rotate.png"
                alt="rotate"
                onClick={() => toggleRotate()}
              />
            </div>
          )}
        </div>
        <div
          className={classNames(
            "relative select-none text-center z-10 overflow-hidden h-auto duration-500",
            {
              "h-[12rem] 2xs:h-[16rem] xs:h-[20rem] sm:h-[24rem] md:h-[28rem] lg:h-[32rem] xl:h-[36rem]":
                device === "Laptop",
              "h-[20rem] 2xs:h-[24rem] xs:h-[32rem] md:h-[36rem] lg:h-[42rem]":
                device === "Tablet",
              "h-[38rem] 2xs:h-[40rem] md:h-[41rem]": device === "Mobile",
            }
          )}
        >
          <Desktop
            url={url}
            isolate={isolate}
            display={device === "All" || device === "Desktop"}
          />

          <Laptop
            url={url}
            isolate={isolate}
            display={device === "All" || device === "Laptop"}
          />

          <Tablet
            url={url}
            isolate={isolate}
            rotate={rotate}
            display={device === "All" || device === "Tablet"}
          />

          <Mobile
            url={url}
            isolate={isolate}
            display={device === "All" || device === "Mobile"}
          />
        </div>
      </div>
      <div className="container mx-auto px-4 h-10 max-md:-mb-2 z-20">
        <a
          className="flex gap-1 items-center justify-center leading-none max-w-full"
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          <span className="inline-block break-all underline underline-offset-2">
            Open in new tab
          </span>
          <img
            className="h-6 flex-shrink-0"
            src="img/icon/open-link.png"
            alt="open link"
          />
        </a>
      </div>
    </>
  );
};

export default Preview;

function PageURL({ srcUrl }) {
  const [url, setURL] = useState(srcUrl);
  const [tempURL, setTempURL] = useState(url);
  const navigate = useNavigate();

  const submitURL = (e) => {
    e.preventDefault();
    setURL(tempURL);
    navigate("/preview?url=" + tempURL);
  };

  return (
    <div className="relative text-center m-auto mb-12 z-20 max-w-[500px]">
      <form
        onSubmit={(e) => submitURL(e)}
        className="inline-flex shadow-md rounded-md w-full"
      >
        <input
          className="focus:outline-none border py-2 px-4 rounded-s-md font-bold w-full"
          type="text"
          placeholder="Enter URL here"
          value={tempURL}
          onChange={(e) => setTempURL(e.target.value)}
        />
        <input
          type="button"
          className="w-[50px] border border-green-500 rounded-e-md px-4 bg-green-500 text-white shadow-md cursor-pointer"
          value="⯈"
          title="Go"
          onClick={(e) => submitURL(e)}
        />
      </form>
    </div>
  );
}
