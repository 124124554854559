import { useState } from "react";
import { useLocation } from "react-router-dom";
import Available from "./components/Available";
import Rain from "./components/Rain/Rain";
import Header from "./components/Header";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import About from "./About";

const Hello = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/" && <LoadHello />}
      {location.pathname !== "/" && <LoadContent />}
    </>
  );
};

export default Hello;

function LoadHello() {
  const [zoomout, setZoomout] = useState(false);
  const [loadContent, setLoadContent] = useState(false);
  const [hello, setHello] = useState(true);

  const loadPage = () => {
    setLoadContent(true);
    setZoomout(true);
    setTimeout(() => {
      setHello(false);
    }, 1000);
  };
  return (
    <>
      {loadContent && <LoadContent />}
      {hello && (
        <div
          className={`container m-auto px-4 min-h-dvh flex flex-col gap-4 items-center justify-center -mb-20 fixed top-0 left-0 right-0 bg-white ${
            zoomout ? " md:scale-[0] rounded-full opacity-0 " : ""
          } duration-1000`}
        >
          <div
            className={`flex items-center justify-between gap-4 2xs:gap-5 z-10`}
          >
            <div className="cursor-pointer inline-block w-[5rem] h-[5rem] 3xs:w-[5.3125rem] 3xs:h-[5.3125rem] 2xs:w-24 2xs:h-24 xs:w-32 xs:h-32 sm:w-44 sm:h-44 rounded-full overflow-hidden ring-4">
              <img
                className="transition-all ease-in-out duration-500 hover:scale-105"
                src="img/profile_photo.png"
                alt="Muhammad Russell"
                onClick={() => loadPage()}
              />
            </div>
            <Rain
              content="Hello"
              containerClasses={`text-[4.8rem] 3xs:text-[5.1875rem] 2xs:text-[6.25rem] xs:text-[7.5rem] sm:text-[9.375rem] font-bold`}
            />
          </div>
          <div>
            <h2 className="text-[1.55rem] 3xs:text-[1.75rem] 2xs:text-[2rem] xs:text-[2.5rem] sm:text-[3.25rem] font-bold text-justify mb-3">
              I'm{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0075B4] to-[#6FDA44] ">
                Muhammad Russell
              </span>
            </h2>
            <div className="flex items-center gap-5 justify-between flex-col-reverse 2xs:flex-row">
              <span
                to="/about"
                className="px-3 py-1 cursor-pointer select-none text-sm text-[#0075B4] border border-[#0075B4] rounded-md"
                onClick={() => loadPage()}
              >
                About Me
              </span>
              <Available hello={true} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function LoadContent() {
  return (
    <>
      <Header />
      <Nav hello={true} /> <About /> <Footer />
    </>
  );
}
