import { useState } from "react";
import Loading from "../Loading";
import classNames from "classnames";

function Laptop({ display = true, isolate, url, title = "" }) {
  const [loading, setLoading] = useState(true);

  return (
    <div
      className={classNames("duration-1000", {
        "absolute w-[620px] xl:w-[700px] -right-5 -bottom-20 xl:bottom-0":
          !isolate,
        "absolute left-0 right-0 top-0 w-[280px] 3xs:w-[320px] 2xs:w-[380px] xs:w-[500px] sm:w-[600px] md:w-[700px] lg:w-[800px] xl:w-[900px] mx-auto mb-9 md:mb-5":
          isolate,
        "translate-x-full opacity-0": !display,
      })}
    >
      <img
        className="m-auto pointer-events-none select-none"
        src="img/device/laptop.png"
        alt="Laptop"
      />
      <div className="absolute top-[8.8%] -left-px 2xs:left-0 right-0 m-auto flex justify-center w-[72.8572%] h-[76.191%] bg-white">
        <iframe
          src={url}
          width="1020"
          height="642"
          title={title.length < 1 ? "Preview: " + url : title}
          className={classNames(
            "absolute top-0 left-0 right-0 m-auto origin-top-left",
            {
              "scale-[0.445] xl:scale-50": !isolate,
              "scale-[0.2] 3xs:scale-[0.23] 2xs:scale-[0.272] xs:scale-[0.357] sm:scale-[0.43] md:scale-[0.504] lg:scale-[0.575] xl:scale-[0.645]":
                isolate,
            }
          )}
          onLoad={() => setLoading(false)}
        ></iframe>
        {loading && <Loading />}
      </div>
    </div>
  );
}

export default Laptop;
